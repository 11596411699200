/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Events Calendar</span>
        </div>
      </div>

        <SidebarMenuItem fontIcon='bi-chat-left' icon='/media/icons/duotune/communication/com005.svg' to='/' title='Conferences' />
        <SidebarMenuItem fontIcon='bi-chat-left' icon='/media/icons/duotune/communication/com013.svg' to='/' title='Seminars'  />
        <SidebarMenuItem fontIcon='bi-chat-left' icon='/media/icons/duotune/communication/com003.svg' to='/' title='Workshops'  />
      
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crop Services</span>
        </div>
      </div>
   
        <SidebarMenuItem fontIcon='bi-chat-left' icon='/media/icons/duotune/communication/com002.svg' to='/' title='Crop Performance'  />
        <SidebarMenuItem fontIcon='bi-chat-left' icon='/media/icons/duotune/communication/com001.svg' to='/' title='Crop Insurance' />
        <SidebarMenuItem fontIcon='bi-chat-left' icon='/media/icons/duotune/communication/com014.svg' to='/' title='Crop Cooperatives' />


      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Livestock Services</span>
        </div>
      </div>

        <SidebarMenuItem fontIcon='bi-chat-left' icon='/media/icons/duotune/communication/com004.svg' to='/' title='Feeds, Grazing and Meat Products' />
        <SidebarMenuItem fontIcon='bi-chat-left' icon='/media/icons/duotune/communication/com010.svg' to='/' title='Livestock Disease Protection'  />
        <SidebarMenuItem fontIcon='bi-chat-left' icon='/media/icons/duotune/communication/com009.svg' to='/' title='Livestock Insurance'  />
      
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Collab</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Chat' hasBullet={true} />
      </SidebarMenuItemWithSub>
      
   
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Reports</span>
        </div>
      </div>
        <SidebarMenuItem to='/crafted/widgets/tables' title='Reports' 
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
        />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Admin</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      />
    </>
  )
}

export {SidebarMenuMain}
