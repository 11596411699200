/* eslint-disable jsx-a11y/anchor-is-valid */
import {  useEffect } from 'react' //useState, useMemo, useRef,
import {FC}  from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { institutions } from '../../../_metronic/assets/data/institutions'
import {
  ListsWidget2,
  ListsWidget21,
  ListsWidget22,
  // TablesWidget10
} from '../../../_metronic/partials/widgets'
import {  Marker } from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import { MapContainer,TileLayer,Popup } from 'react-leaflet'
// const center = {
//   lat: 0.34057,
//   lng: 37.58426,
// }


let institutionGPS:[{name:string,countryCode:string,lat:string,lng:string,description:string}] = [{name:'',countryCode:'',lat:'',lng:'',description:''}];

let usedInstitutionCodes:string[] = []
institutionGPS.pop()

institutions.map((ins,insIndex)=>{
  if(!usedInstitutionCodes.includes(ins.CountryCode)){
    usedInstitutionCodes.push(ins.CountryCode)
    let list = ""
    let countryInstitutions = institutions.filter((ist) => ist.CountryCode === ins.CountryCode)
    countryInstitutions.map((ci)=> list+= ci.name + ", \n")
    institutionGPS.push({
      name:ins.country,
      countryCode: ins.CountryCode,
      lat:ins.CapitalLatitude,
      lng:ins.CapitalLongitude,
      description: list
    })
  
  }

  return 0;

})



// function DraggableMarker() {

//   const [position, setPosition] = useState(center)
//   const markerRef = useRef<any>(null)
//   const eventHandlers = useMemo(
//       () => ({
//       dragend() {
//           const marker = markerRef.current
//           if (marker != null) {
//             let pos = marker.getLatLng();
//             setPosition(pos);

//             console.log("Position Lat: ",pos.lat);
//             console.log("Position Lng: ",pos.lng);
//           }
//       },
//       }),
//       [],
//   )
//   return (
//       <Marker
//       draggable={true}
//       eventHandlers={eventHandlers}
//       position={position}
//       ref={markerRef}>
//       </Marker>
//   )
// }


const DashboardPage: FC = ()=>{

  useEffect(() => {
    const L = require("leaflet");

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png")
    });
  }, []);
  

  return (
    <>

      {/* begin::Row */}
      <div className='row gx-5 gx-xl-10'>
        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xxl-12 py-5' >
                <MapContainer center={[0.34057,37.58426]} zoom={7} style={{ height: "100vh",border: "2px solid #223388" }}>
                  <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />                                                                                        
                  {/* <DraggableMarker />
                  <Marker position={center}>
                    <Popup>
                        <strong>{'Welcome to IGAD'}</strong>
                    </Popup>
                  </Marker> */}

                  {institutionGPS.map((ac,acIndex)=>(
                    <Marker key={acIndex} position={[parseFloat(ac.lat),parseFloat(ac.lng)]}>
                    <Popup>
                        <strong>{ac.description}</strong>
                    </Popup>
                    </Marker>
                  ))}
                  
              </MapContainer>
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        <div className='col-xl-4'>
          <ListsWidget2 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget21 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget22 className='card-xl-stretch mb-5 mb-xl-8'/>
          {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
