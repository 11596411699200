/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid' style={{width:'50%',float:'left'}}>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10'>
             {/* begin::Logo */}
              <Link to='/' className='mb-12'>
                <img alt='Logo' src={toAbsoluteUrl('/media/logos/ip-logo5.png')} className='h-75px' />
              </Link>
              {/* end::Logo */}
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5' style={{
          zIndex: "1",
          background: "white",
          width: "100vw",
          padding: "10px 20px",
          position: "fixed",
          bottom: "0",
          left: "0",
          boxShadow: "#ccc 20px 20px 20px 20px"
        }}>
          {/* begin::Links */}
          <div className='d-flex fw-semibold text-primary fs-base'>
            <a href='#' className='px-5' target='_blank'>
              Terms
            </a>

            <a href='#' className='px-5' target='_blank'>
              Plans
            </a>

            <a href='#' className='px-5' target='_blank'>
              Contact Us
            </a>
          </div>
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2 position-fixed'
        style={{backgroundColor:'#008242','position':'fixed','right':'0','top':'0'}}
      >
        {/* begin::Content {backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg4.png')})`}*/}
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
         

          {/* begin::Image */}
          <img
            className='mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20'
            src={toAbsoluteUrl('/media/logos/IGAD-big-logo.png')}
            alt=''
          />
          {/* end::Image */}

          {/* begin::Title */}
          <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>
            Transformative regional capacities for sustainable development
          </h1>
          {/* end::Title */}

          {/* begin::Text */}
          <div className='text-white fs-base text-center'>
          Promote{' '}
            <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
            regional cooperation and integration 
            </a>
            to add value to Member States’ efforts in achieving <br />peace, security, and prosperity. A  enjoy high quality of life
          
            <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
            resilient, peaceful, prosperous 
            </a>
            and integrated region where  <br />citizens enjoy high quality of life.

          </div>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          {/* end::Text */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
