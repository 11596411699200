/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {getUserByToken, register} from '../core/_requests'
import {Link} from 'react-router-dom'
//import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import React from 'react'
import Select from 'react-select'



const initialValues = {
  accountType:0,
  companyName: '',
  companyAddress: '',
  companyEmail: '',
  companyPhone:'',
  companyWebsite:'',
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  changepassword: '',
  phone:'',
  organization:'',
  areaOfInterest:[],
  serviceCategory:0,
  otherServiceCategory:'',
  country:0,
  gender:0,
  acceptTerms: false,
}
const accountTypeOptions = [
  {id:1,'name':'Individual'},
  {id:2,'name':'Organization'},
]
const genderOptions = [
  {id:1,'name':'Male'},
  {id:2,'name':'Female'},
]

const countryOptions = [
  {id:1,'name':'DJibouti'},
  {id:2,'name':'Ethiopia'},
  {id:3,'name':'Kenya'},
  {id:4,'name':'Somalia'},
  {id:5,'name':'Sudan'},
  {id:6,'name':'Uganda'},
  {id:7,'name':'Eritrea'},
]


const serviceCategoryOptions = [
  {id:1,'name':'Government'},
  {id:2,'name':'UN Agencies'},
  {id:3,'name':'Civil Society'},
  {id:4,'name':'Academia / Researcher'},
  {id:5,'name':'Cooperative'},
  {id:6,'name':'Insurance provider'},
  {id:7,'name':'Other (Specify)'},
  {id:8,'name':'International Organizations'},
  {id:9,'name':'Regional Institutions'},
]

const areaofInterestOptions = [
  {id:1,'name':'Crop'},
  {id:2,'name':'Livestock'},
]



const registrationSchema = Yup.object().shape({
  accountType: Yup.number()
    .min(1,'Please Select a type')
    .max(7,'Select a valid type'),
  companyName: Yup.string()
    .when("accountType", {
      is: (val: number) => val === 2,
      then: Yup.string().required("Organization Name is required").min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols')
    }),
  companyAddress: Yup.string()
    .when("accountType", {
      is: (val: number) => val === 2,
      then: Yup.string().required("Organization Address is required").min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols')
    }),
  companyEmail: Yup.string()
    .when("accountType", {
      is: (val: number) => val === 2,
      then: Yup.string().email('Wrong email format').required("Organization Email is required").min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols')
    }),
  companyPhone: Yup.string()
    .when("accountType", {
      is: (val: number) => val === 2,
      then: Yup.string().min(10, 'Minimum 10 symbols').max(14, 'Maximum 14 symbols')
    }),
  companyWebsite: Yup.string()
    .when("accountType", {
      is: (val: number) => val === 2,
      then: Yup.string().min(10, 'Minimum 10 symbols').max(14, 'Maximum 14 symbols')
    }),
  firstname: Yup.string()
    .when("accountType", {
      is: (val: number) => val === 1,
      then: Yup.string().required("First Name is required").min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols')
    }),
  lastname: Yup.string()
    .when("accountType", {
      is: (val: number) => val === 1,
      then: Yup.string().required("Last Name is required").min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols')
    }),
  gender: Yup.number()
    .when("accountType", {
      is: (val: number) => val === 1,
      then: Yup.number().required("Gender is required")
    }),
  email: Yup.string()
    .when("accountType", {
      is: (val: number) => val === 1,
      then: Yup.string().email('Wrong email format').required("Email is required").min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols')
    }),
  phone: Yup.string()
    .when("accountType", {
      is: (val: number) => val === 1,
      then: Yup.string().min(10, 'Minimum 10 symbols').max(14, 'Maximum 14 symbols')
    }),
  organization: Yup.string()
    .when("accountType", {
      is: (val: number) => val === 1,
      then: Yup.string().min(3, 'Minimum 10 symbols').max(50, 'Maximum 50 symbols')
    }),
  country: Yup.number()
    .min(1,'Please Select a country')
    .max(7,'Select a valid country')
    .required('Country is required'),
  serviceCategory: Yup.number()
    .min(1,'Please Select a service')
    .max(7,'Select a valid service')
    .required('Service Category is required'),
  otherServiceCategory:Yup.string()
    .when("serviceCategory", {
      is: (val: number) => val === 7,
      then: Yup.string().required("Enter other service category")
    }),
  areaofInterest:Yup.array(
    Yup.number()
    ).min(1,'Select an area of interest'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})


export function Registration() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      console.log(values)
      try {
        const {data: auth} = await register(
          values.email===''||values.email===null?values.companyEmail:values.email,
          values.firstname,
          values.lastname,
          values.gender,
          values.password,
          values.changepassword,
          values.organization,
          values.country,
          values.phone,
          values.serviceCategory,
          values.accountType,
          values.areaOfInterest,
          values.otherServiceCategory,
          values.companyName,
          values.companyAddress,
          values.companyEmail,
          values.companyPhone,
          values.companyWebsite
        )
        saveAuth(auth)
        const {data: user} = await getUserByToken(auth.api_token)
        setCurrentUser(user)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The registration details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
        {/* end::Title */}

        <div className='text-gray-500 fw-semibold fs-6'>Your Social Collaboration Platform for Regional Stability and Security</div>
      </div>
      {/* end::Heading */}


      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Service category */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Type of Account</label>
        <Select
          name='accountType'
          options = {accountTypeOptions.map((accountType) => ({"value":accountType.id,"label":accountType.name}))} 
          onChange={(v)=>{formik.setFieldValue('accountType',v?.value)}}
        />
        {formik.touched.accountType && formik.errors.accountType && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.accountType}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}


      {formik.values?.accountType === 1 && <>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>First Name</label>
          <input
            placeholder='First name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('firstname')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.firstname && formik.errors.firstname,
              },
              {
                'is-valid': formik.touched.firstname && !formik.errors.firstname,
              }
            )}
          />
          {formik.touched.firstname && formik.errors.firstname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.firstname}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Last Name</label>
          <input
            placeholder='Last name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('lastname')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.lastname && formik.errors.lastname,
              },
              {
                'is-valid': formik.touched.lastname && !formik.errors.lastname,
              }
            )}
          />
          {formik.touched.lastname && formik.errors.lastname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.lastname}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Gender</label>
          <Select
            name='gender'
            options = {genderOptions.map((genderOption) => ({"value":genderOption.id,"label":genderOption.name}))} 
            onChange={(v)=>{formik.setFieldValue('gender',v?.value)}}
          />
          {formik.touched.gender && formik.errors.gender && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.gender}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Email</label>
          <input
            placeholder='Email'
            type='email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Phone</label>
          <div  
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.phone && formik.errors.phone},
              {
                'is-valid': formik.touched.phone && !formik.errors.phone,
              }
            )}
          >
            <PhoneInput
              country={'ke'}
              preferredCountries={['ke','ug','so','et','ss','sd','dj','er']}
              onChange={phone=>formik.setFieldValue('phone',phone)}
              // {...formik.getFieldProps('phone')}
              
            />
          </div>
          
        
          {formik.touched.phone && formik.errors.phone && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.phone}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Organization</label>
          <input
            placeholder='Organization'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('organization')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.organization && formik.errors.organization},
              {
                'is-valid': formik.touched.organization && !formik.errors.organization,
              }
            )}
          />
          {formik.touched.organization && formik.errors.organization && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.organization}</span>
              </div>
            </div>
          )}
        </div>

      </>}
      {formik.values?.accountType === 2 && <>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Organization Name</label>
          <input
            placeholder='Organization name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('companyName')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.companyName && formik.errors.companyName,
              },
              {
                'is-valid': formik.touched.companyName && !formik.errors.companyName,
              }
            )}
          />
          {formik.touched.companyName && formik.errors.companyName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.companyName}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Organization Address</label>
          <input
            placeholder='Organization address'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('companyAddress')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.companyAddress && formik.errors.companyAddress,
              },
              {
                'is-valid': formik.touched.companyAddress && !formik.errors.companyAddress,
              }
            )}
          />
          {formik.touched.companyAddress && formik.errors.companyAddress && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.companyAddress}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Organization Email</label>
          <input
            placeholder='Organization email'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('companyEmail')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.companyEmail && formik.errors.companyEmail,
              },
              {
                'is-valid': formik.touched.companyEmail && !formik.errors.companyEmail,
              }
            )}
          />
          {formik.touched.companyEmail && formik.errors.companyEmail && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.companyEmail}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Organization Website</label>
          <input
            placeholder='Organization website'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('companyWebsite')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.companyWebsite && formik.errors.companyWebsite,
              },
              {
                'is-valid': formik.touched.companyWebsite && !formik.errors.companyWebsite,
              }
            )}
          />
          {formik.touched.companyWebsite && formik.errors.companyWebsite && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.companyWebsite}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Organization Phone</label>
        <div  
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.companyPhone && formik.errors.companyPhone},
            {
              'is-valid': formik.touched.companyPhone && !formik.errors.companyPhone,
            }
          )}
        >
          <PhoneInput
            country={'ke'}
            preferredCountries={['ke','ug','so','et','ss','sd','dj','er']}
            onChange={phone=>formik.setFieldValue('companyPhone',phone)}
            // {...formik.getFieldProps('companyPhone')}
            
          />
        </div>
        {formik.touched.companyPhone && formik.errors.companyPhone && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.companyPhone}</span>
            </div>
          </div>
        )}
      </div>
      </>}

      


      

      {/* begin::Form group Country */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Country</label>

        <Select
          name='country'
          isMulti={false}
          options = {countryOptions.map((country) => ({"value":country.id,"label":country.name}))} 
          onChange={(v)=>{formik.setFieldValue('country',v?.value)}}
        />
  
        {formik.touched.country && formik.errors.country && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.country}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Service category */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Service category</label>

        <Select
          id='serviceCategory'
          isMulti={false}
          options = {serviceCategoryOptions.map((serviceCategory) => ({"value":serviceCategory.id,"label":serviceCategory.name}))} 
          onChange={(v)=>{formik.setFieldValue('serviceCategory',v?.value)}}
        />

        <div className='py-6'>
          {formik.values.serviceCategory === 7 && 
          <input  
            placeholder='Other Service category' 
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.otherServiceCategory && formik.errors.otherServiceCategory},
              {
                'is-valid': formik.touched.otherServiceCategory && !formik.errors.otherServiceCategory,
              }
            )}
            type="text" 
            {...formik.getFieldProps('otherServiceCategory')}  />}
          </div>
        {formik.touched.serviceCategory && formik.errors.serviceCategory && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.serviceCategory}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Service category */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Areas of Interest </label>
        <Select
          name='areaOfInterest[]'
          isMulti={true}
          options = {areaofInterestOptions.map((areaOfInterest) => ({"value":areaOfInterest.id,"label":areaOfInterest.name}))} 
          onChange={(v)=>{
            formik.setFieldValue('areaOfInterest',v.map((val)=>val?.value))
          }}
          onBlur={formik.handleBlur}
        />
        {formik.touched.areaOfInterest && formik.errors.areaOfInterest && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.areaOfInterest}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <input
          type='password'
          placeholder='Password confirmation'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            I Accept the{' '}
            <a
              href='https://keenthemes.com/metronic/?page=faq'
              target='_blank'
              className='ms-1 link-primary'
            >
              Terms
            </a>
            .
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancel
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
