/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import { institutions } from '../../../assets/data/institutions'


type Props = {
  className: string
}

const localInstitutions = institutions.filter((inst) => inst.CountryCode === 'UG')

const ListsWidget21: React.FC<Props> = ({className}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'>Government Organizations</h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 />
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>

        {localInstitutions.length>0&&localInstitutions.map((lin,linIndex)=>{

          return (
            <div key={linIndex} className='d-flex align-items-center mb-7'>
              <div className='symbol symbol-50px me-5'>
                <img src={toAbsoluteUrl('/media/misc/qr.png')} className='' alt='' />
              </div>
              <div className='flex-grow-1'>
                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                  {lin.name}
                </a>
                <span className='text-muted d-block fw-semibold'>{lin.country}</span>
              </div>
            </div>
          )

        })}
        
 

      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget21}
