export const institutions = [
	{name:"Ministry of Agriculture and Livestock Resources, and Ministry of Finance; Regional Bureaus of Agriculture",country:"Ethiopia",institutionType:"Government","CapitalLatitude":"9.033333333333333","CapitalLongitude":"38.700000","CountryCode":"ET"},
	{name:"Regional Micro-finance Institutions (mostly PPP)",country:"Ethiopia",institutionType:"Private","CapitalLatitude":"9.033333333333333","CapitalLongitude":"38.700000","CountryCode":"ET"},
	{name:"Public and Private Insurance Providers – Ethiopian Insurance Corporation (public); Oromia",country:"Ethiopia",institutionType:"Private","CapitalLatitude":"9.033333333333333","CapitalLongitude":"38.700000","CountryCode":"ET"},
	{name:"Insurance Company (PPP), African Insurance Company (private), and Nyla Insurance Company (private)",country:"Ethiopia",institutionType:"Government","CapitalLatitude":"9.033333333333333","CapitalLongitude":"38.700000","CountryCode":"ET"},
	{name:"United Nation’s World Food Program (WFP)",country:"Ethiopia",institutionType:"Government","CapitalLatitude":"9.033333333333333","CapitalLongitude":"38.700000","CountryCode":"ET"},
	{name:"Japan International Cooperation Agency (JICA)",country:"Ethiopia",institutionType:"Government","CapitalLatitude":"9.033333333333333","CapitalLongitude":"38.700000","CountryCode":"ET"},
	{name:"Kifiya Financial Technology",country:"Ethiopia",institutionType:"Government","CapitalLatitude":"9.033333333333333","CapitalLongitude":"38.700000","CountryCode":"ET"},
	{name:"InsuResilience Solutions Fund (ISF) (through Frankfurt School of Finance and Management)",country:"Ethiopia",institutionType:"Government","CapitalLatitude":"9.033333333333333","CapitalLongitude":"38.700000","CountryCode":"ET"},
	{name:"KfW Development Bank;",country:"Ethiopia",institutionType:"Government","CapitalLatitude":"9.033333333333333","CapitalLongitude":"38.700000","CountryCode":"ET"},
	{name:"ICRC",country:"Ethiopia",institutionType:"Private","CapitalLatitude":"9.033333333333333","CapitalLongitude":"38.700000","CountryCode":"ET"},
	{name:"Agri Service Ethiopia",country:"Ethiopia",institutionType:"Government","CapitalLatitude":"9.033333333333333","CapitalLongitude":"38.700000","CountryCode":"ET"},
	{name:"Bahr Dar University",country:"Ethiopia",institutionType:"University","CapitalLatitude":"9.033333333333333","CapitalLongitude":"38.700000","CountryCode":"ET"},

	{name:"Government of Kenya (GoK)",country:"Kenya",institutionType:"Government","CapitalLatitude":"-1.2833333333333332","CapitalLongitude":"36.816667","CountryCode":"KE"},
	{name:"International Livestock Research Institute (ILRI)",country:"Kenya",institutionType:"Private","CapitalLatitude":"-1.2833333333333332","CapitalLongitude":"36.816667","CountryCode":"KE"},
	{name:"Kenya Insurance Regulatory Authority (KIRA)",country:"Kenya",institutionType:"Insurance","CapitalLatitude":"-1.2833333333333332","CapitalLongitude":"36.816667","CountryCode":"KE"},
	{name:"State Department of Livestock (SDL), under the Ministry of Agriculture, Livestock and Fisheries (MoALF)",country:"Kenya",institutionType:"Government","CapitalLatitude":"-1.2833333333333332","CapitalLongitude":"36.816667","CountryCode":"KE"},
	{name:"Kenya Livestock Insurance Program (KLIP)",country:"Kenya",institutionType:"Gopvernment","CapitalLatitude":"-1.2833333333333332","CapitalLongitude":"36.816667","CountryCode":"KE"},
	{name:"United States Agency for International Development (USAID)",country:"Kenya",institutionType:"Private","CapitalLatitude":"-1.2833333333333332","CapitalLongitude":"36.816667","CountryCode":"KE"},
	{name:"World Bank",country:"Kenya",institutionType:"Private","CapitalLatitude":"-1.2833333333333332","CapitalLongitude":"36.816667","CountryCode":"KE"},
	{name:"DFID (UK's Department for International Development)",country:"Kenya",institutionType:"Private","CapitalLatitude":"-1.2833333333333332","CapitalLongitude":"36.816667","CountryCode":"KE"},
	{name:"Financial Sector Deepening Trust (FSD Kenya)",country:"Kenya",institutionType:"Private","CapitalLatitude":"-1.2833333333333332","CapitalLongitude":"36.816667","CountryCode":"KE"},
	{name:"Global Index Insurance Fund (GIIF)",country:"Kenya",institutionType:"Private","CapitalLatitude":"-1.2833333333333332","CapitalLongitude":"36.816667","CountryCode":"KE"},
	
	{name:"The Insurance Supervisory Authority (ISA)",country:"Sudan",institutionType:"Government","CapitalLatitude":"15.6","CapitalLongitude":"32.533333","CountryCode":"SD"},
	{name:"Relevant Government departments (Ministry of Finance and Economic Planning), Ministry of Agriculture, Ministry of Livestock and fisheries",country:"Sudan",institutionType:"Government","CapitalLatitude":"15.6","CapitalLongitude":"32.533333","CountryCode":"SD"},
	{name:"Sheikan Insurance and Reinsurance Company - (owned by the Government of Sudan)",country:"Sudan",institutionType:"Government","CapitalLatitude":"15.6","CapitalLongitude":"32.533333","CountryCode":"SD"},
	{name:"National Reinsurance Company (NIC) (public), ZEPRe (Private), Ta’awuniya Insurance Company (private), Africa Re (private) – (headquartered in Lagos, Nigeria) with a retakaful subsidiary in Sudan, called Africa Takaful Reinsurance Company",country:"Sudan",institutionType:"Government","CapitalLatitude":"15.6","CapitalLongitude":"32.533333","CountryCode":"SD"},
	{name:"Takaful and Re-takaful (Sharia Compliant Reissuance)",country:"Sudan",institutionType:"Government","CapitalLatitude":"15.6","CapitalLongitude":"32.533333","CountryCode":"SD"},
	{name:"Agricultural Bank of Sudan - Microfinance Initiative (ABSUMI) IBLI program",country:"Sudan",institutionType:"Government","CapitalLatitude":"15.6","CapitalLongitude":"32.533333","CountryCode":"SD"},
	{name:"United Nations Development program (UNDP).",country:"Sudan",institutionType:"Government","CapitalLatitude":"15.6","CapitalLongitude":"32.533333","CountryCode":"SD"},
	{name:"United Nation’s World Food Program (WFP)",country:"Sudan",institutionType:"Government","CapitalLatitude":"15.6","CapitalLongitude":"32.533333","CountryCode":"SD"},
	{name:"International Fund for Agricultural Development (IFAD)",country:"Sudan",institutionType:"Government","CapitalLatitude":"15.6","CapitalLongitude":"32.533333","CountryCode":"SD"},
	{name:"World Bank",country:"Sudan",institutionType:"Government","CapitalLatitude":"15.6","CapitalLongitude":"32.533333","CountryCode":"SD"},
	{name:"Higher Sharia Board (HSB)",country:"Sudan",institutionType:"Government","CapitalLatitude":"15.6","CapitalLongitude":"32.533333","CountryCode":"SD"},
	{name:"Sharia Committee of the Insurance companies",country:"Sudan",institutionType:"Government","CapitalLatitude":"15.6","CapitalLongitude":"32.533333","CountryCode":"SD"},
	
	{name:"Insurance Regulatory Authority",country:"Uganda",institutionType:"Government","CapitalLatitude":"0.31666666666666665","CapitalLongitude":"32.550000","CountryCode":"UG"},
	{name:"Government of Uganda represented by the Ministry of Finance Planning and Economic Development (MoFPED) and the private sector",country:"Uganda",institutionType:"Government","CapitalLatitude":"0.31666666666666665","CapitalLongitude":"32.550000","CountryCode":"UG"},
	{name:"Ministry of Agriculture, Animal Industry and Fisheries (MAAIF)",country:"Uganda",institutionType:"Government","CapitalLatitude":"0.31666666666666665","CapitalLongitude":"32.550000","CountryCode":"UG"},
	{name:"Third Northern Uganda Social Action Fund (NUSAFIII)",country:"Uganda",institutionType:"Government","CapitalLatitude":"0.31666666666666665","CapitalLongitude":"32.550000","CountryCode":"UG"},
	{name:"Uganda Insurers Association",country:"Uganda",institutionType:"Government","CapitalLatitude":"0.31666666666666665","CapitalLongitude":"32.550000","CountryCode":"UG"},
	{name:"Uganda Agricultural Insurance Scheme",country:"Uganda",institutionType:"Government","CapitalLatitude":"0.31666666666666665","CapitalLongitude":"32.550000","CountryCode":"UG"},
	{name:"eLEAF- (private)",country:"Uganda",institutionType:"Government","CapitalLatitude":"0.31666666666666665","CapitalLongitude":"32.550000","CountryCode":"UG"},
	{name:"Agro Consortium- brings together 5 private companies",country:"Uganda",institutionType:"Government","CapitalLatitude":"0.31666666666666665","CapitalLongitude":"32.550000","CountryCode":"UG"},
	{name:"Agriculture Reinsurance Company (ARC) - Private",country:"Uganda",institutionType:"Government","CapitalLatitude":"0.31666666666666665","CapitalLongitude":"32.550000","CountryCode":"UG"},
	{name:"OKO Finance (Private Micro-Finance service provider)",country:"Uganda",institutionType:"Government","CapitalLatitude":"0.31666666666666665","CapitalLongitude":"32.550000","CountryCode":"UG"},
	{name:"Uganda Agriculture Insurance Scheme8 (UAIS).",country:"Uganda",institutionType:"Government","CapitalLatitude":"0.31666666666666665","CapitalLongitude":"32.550000","CountryCode":"UG"},
	{name:"World Bank",country:"Uganda",institutionType:"Government","CapitalLatitude":"0.31666666666666665","CapitalLongitude":"32.550000","CountryCode":"UG"},
	
	{name:"The GoS through the Central Bank of Somalia (the Insurance Regulator in the country)",country:"Somalia",institutionType:"Government","CapitalLatitude":"2.066666666666667","CapitalLongitude":"45.333333","CountryCode":"SO"},
	{name:"International Livestock Research Institute (ILRI)",country:"Somalia",institutionType:"Government","CapitalLatitude":"2.066666666666667","CapitalLongitude":"45.333333","CountryCode":"SO"},
	{name:"Food and Agriculture Organization of the United Nations (FAO)",country:"Somalia",institutionType:"Government","CapitalLatitude":"2.066666666666667","CapitalLongitude":"45.333333","CountryCode":"SO"},
	{name:"European Union (EU)",country:"Somalia",institutionType:"Government","CapitalLatitude":"2.066666666666667","CapitalLongitude":"45.333333","CountryCode":"SO"},
	{name:"The European Commission on Humanitarian Aid and Civil Protection (ECHO)",country:"Somalia",institutionType:"Government","CapitalLatitude":"2.066666666666667","CapitalLongitude":"45.333333","CountryCode":"SO"},
	{name:"World Bank",country:"Somalia",institutionType:"Government","CapitalLatitude":"2.066666666666667","CapitalLongitude":"45.333333","CountryCode":"SO"},
	{name:"Takaful Africa10",country:"Somalia",institutionType:"Government","CapitalLatitude":"2.066666666666667","CapitalLongitude":"45.333333","CountryCode":"SO"},
	{name:"Organization for Peace, Relief and Development (OPRD); Assistance Mission for Africa (AMA)",country:"Somalia",institutionType:"Government","CapitalLatitude":"2.066666666666667","CapitalLongitude":"45.333333","CountryCode":"SO"},
	
	{name:"GMX and AMERGA (The two insurance companies in the country)",country:"Djibouti",institutionType:"Government","CapitalLatitude":"11.583333333333334","CapitalLongitude":"43.150000","CountryCode":"DJ"},
	{name:"Key Technical staff under the Ministry of Economy and Finance (MEF)",country:"Djibouti",institutionType:"Government","CapitalLatitude":"11.583333333333334","CapitalLongitude":"43.150000","CountryCode":"DJ"},
	{name:"Insurance Control Service (ICS) – Ministry of Finance;",country:"Djibouti",institutionType:"Government","CapitalLatitude":"11.583333333333334","CapitalLongitude":"43.150000","CountryCode":"DJ"},
	{name:"Africa Risk Capacity",country:"Djibouti",institutionType:"Government","CapitalLatitude":"11.583333333333334","CapitalLongitude":"43.150000","CountryCode":"DJ"}
]