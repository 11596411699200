import axios from 'axios'
import {AuthModel, UserModel} from './_models'
import config from '../../../../_config/config.json'

const API_URL = process.env.REACT_APP_API_URL || config.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/user/verify-user-token`
export const LOGIN_URL = `${API_URL}/user/login`
export const REGISTER_URL = `${API_URL}/user/sign-up`
export const REQUEST_PASSWORD_URL = `${API_URL}/user/forgot-password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  gender: number,
  password: string,
  password_confirmation: string,
  organization: string,
  country: number,
  phone: string,
  serviceCategory: number,
  accountType: number,
  areaOfInterest: Array<number>,
  otherServiceCategory: string,
  companyName: string,
  companyAddress: string,
  companyEmail: string,
  companyPhone: string,
  companyWebsite: string,
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    gender,
    password,
    password_confirmation,
    organization,
    country,
    phone,
    serviceCategory,
    accountType,
    areaOfInterest,
    otherServiceCategory,
    companyName,
    companyAddress,
    companyEmail,
    companyPhone,
    companyWebsite,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
